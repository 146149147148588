import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { VscMenu } from '@react-icons/all-files/vsc/VscMenu'
import { VscClose } from '@react-icons/all-files/vsc/VscClose'
import { FiMail } from '@react-icons/all-files/fi/FiMail'
import { TiSocialTwitter } from '@react-icons/all-files/ti/TiSocialTwitter'
import { TiSocialFacebook } from '@react-icons/all-files/ti/TiSocialFacebook'
import { BsFillPersonFill } from '@react-icons/all-files/bs/BsFillPersonFill'
import MailTo from './MailTo'
import LanguageSelector from './LanguageSelector'

const items: { label: string; path: string; t: string }[] = [
  { label: 'home', path: '/', t: 'home' },
  { label: 'philosophy', path: '/philosophy', t: 'philosophy' },
  { label: 'stories', path: '/stories', t: 'story' },
  { label: 'how to get?', path: '/purchase', t: 'purchase' },
]

const sns = {
  fb: 'https://www.facebook.com/formothree',
  twitter: 'https://twitter.com/plus886',
}

const GlobalMenuBody: React.FC<{
  open: boolean
  onClose: (e: React.MouseEvent) => void
}> = ({ open, onClose }) => {
  const { t } = useTranslation()
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          key="global-menu-body"
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <ScrollLock>
            <div
              className="h-screen w-screen fixed top-0 left-0 z-20 bg-opacity-98 bg-navy text-white"
              onClick={onClose}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 h-5/6 md:h-full">
                <div className="flex flex-initial flex-col justify-center items-center md:items-end md:px-16 lg:px-28">
                  <p className="text-4xl md:text-7xl tracking-widest">
                    Gardener
                  </p>
                  <p className="tracking-widest pt-2 text-sm md:text-md">
                    by Kyle Ideta
                  </p>
                </div>
                <div className="flex items-start md:items-center justify-center text-center text-sm md:text-lg uppercase tracking-copy">
                  <ul className="w-64 md:w-80">
                    {items.map(item => (
                      <li className="py-3" key={item.label}>
                        <Link to={item.path} className="block py-2">
                          {t(item.t)}
                        </Link>
                      </li>
                    ))}
                    <li className="py-10 flex justify-around items-center text-2xl">
                      <a href={sns.fb} target="_blank" rel="noreferrer">
                        <TiSocialFacebook />
                      </a>
                      <a href={sns.twitter} target="_blank" rel="noreferrer">
                        <TiSocialTwitter />
                      </a>
                      <a
                        href="https://kyleideta.art"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsFillPersonFill />
                      </a>
                      <MailTo email="formothree@gmail.com">
                        <FiMail />
                      </MailTo>
                    </li>
                    <li className="pt-6 md:pt-20 flex justify-center items-center text-sm md:text-base tracking-widest">
                      <LanguageSelector />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ScrollLock>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const GlobalMenu: React.FC = () => {
  const [open, setOpen] = useState(false)
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpen(false)
  }
  return (
    <>
      {open ? (
        <button
          onClick={() => setOpen(false)}
          className="fixed top-8 right-8 z-30 text-2xl text-white fill-current"
        >
          <VscClose />
        </button>
      ) : (
        <button
          onClick={() => setOpen(true)}
          className="fixed top-8 right-8 z-30 text-2xl"
        >
          <VscMenu />
        </button>
      )}
      <GlobalMenuBody open={open} onClose={handleClose} />
    </>
  )
}

export default GlobalMenu
