import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from 'react-helmet'
// @ts-ignore
import OgImage from 'src/images/og_image.jpg'
// @ts-ignore
import TwitterImage from 'src/images/twitter_image.jpg'

type SEOProps = {
  description?: string | null
  meta?: { name: string; content: string }[]
  title?: string | null
}

const SEO: React.FC<SEOProps> = ({ description, meta = [], title }) => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const { i18n } = useTranslation()
  const metaDescription: string = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const snsTitle = `${title ? `${title} | ` : ''}${defaultTitle}`
  const metaMap: Map<string, string | undefined> = new Map([
    ['description', metaDescription],
    ['og:title', snsTitle],
    ['og:description', metaDescription],
    ['og:type', 'website'],
    ['og:image', `https://gardener.kyleideta.art${OgImage}`],
    ['twitter:card', `summary_large_image`],
    ['twitter:image', `https://gardener.kyleideta.art${TwitterImage}`],
    ['twitter:creator', site.siteMetadata?.author],
    ['twitter:title', snsTitle],
    ['twitter:description', metaDescription],
    ['twitter:site', site.siteMetadata?.author],
  ])

  meta.forEach(item => {
    if (!item.content) return
    metaMap.set(item.name, item.content)
  })

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language,
      }}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      title={title!}
      meta={Array.from(metaMap).map(item => ({
        name: item[0],
        property: item[0],
        content: item[1],
      }))}
    >
      <script type="application/javascript">
        {`
          {
            (function(d) {
              var config = {
                kitId: 'sip4kil',
                scriptTimeout: 3000,
                async: true
              },
              h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
            })(document);
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEO
