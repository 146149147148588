import React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'

const label: { [key: string]: string } = {
  'zh-tw': '繁體中文',
  'ja-jp': '日本語',
  'en-us': 'English',
}

const LanguageSelector: React.FC = () => {
  const { languages, originalPath } = useI18next()
  return (
    <ul className="w-full flex justify-around items-center">
      {languages.map(lng => (
        <li key={lng}>
          <Link to={originalPath} language={lng}>
            {label[lng]}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default LanguageSelector
