import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import clsx from 'clsx'
import 'tailwindcss/tailwind.css'
import 'src/global.css'
import GlobalMenu from './GlobalMenu'
//@ts-ignore
import IndexImage from 'src/images/background/index_2.jpg'
//@ts-ignore
import VerticalBorder from 'src/images/background/border_vertical.png'
//@ts-ignore
import HorizontalBorder from 'src/images/background/border_horizontal.png'

type LayoutProps = {
  heroImagePath?: string
  title?: string | null
  subTitle?: string | null
  backgroundPosition?: 'right' | 'left' | 'center'
}

const Layout: React.FC<LayoutProps> = ({
  children,
  heroImagePath = IndexImage,
  title,
  subTitle,
  backgroundPosition = 'center',
}) => {
  const { language } = useI18next()
  return (
    <>
      <div
        className={clsx(
          'w-screen md:w-screen-1/2 h-screen-7/10 md:h-screen relative md:fixed md:top-0 md:left-0 bg-no-repeat bg-cover md:z-0'
        )}
        style={{ backgroundImage: `url(${heroImagePath})`, backgroundPosition }}
      >
        <div
          className="hidden md:block h-full absolute top-0 right-0 bg-no-repeat bg-right bg-contain"
          style={{
            backgroundImage: `url(${VerticalBorder})`,
            width: 44,
            right: -1,
          }}
        ></div>
        <div
          className="md:hidden w-full absolute bottom-0 left-0 bg-no-repeat bg-left-bottom bg-cover"
          style={{
            backgroundImage: `url(${HorizontalBorder})`,
            height: 44,
            bottom: -1,
          }}
        ></div>
      </div>
      <div className="absolute md:static top-0 md:top-auto left-0 md:left-auto w-screen md:min-h-screen md:grid md:gap-0 md:grid-cols-2">
        <div
          className="h-screen-7/10 md:h-auto flex flex-col justify-center items-center max-h-screen z-0 text-white"
          style={{ textShadow: '0px 0px 4px rgba(0,0,0,0.7)' }}
        >
          <div className="md:fixed flex flex-col items-center">
            <h1
              className={clsx('text-xl md:text-2xl', {
                'tracking-widest md:tracking-copy': language !== 'en-us',
                'tracking-widest': language === 'en-us',
              })}
            >
              {title}
            </h1>
            <h2 className="text-sm md:text-base tracking-widest">{subTitle}</h2>
          </div>
        </div>
        <div className="px-6 md:px-12">{children}</div>
      </div>
      <GlobalMenu />
    </>
  )
}

export default Layout
